@use "sass:meta";
@use "themes/quidel.theme";

/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
@include meta.load-css("@ng-select/ng-select/themes/default.theme.css");


.top-most {
  z-index: 99;
}
