// See: constants.ts

$crm-command-bar-height-desktop: 56px !default;
$crm-command-bar-height-mobile: 0 !default;

$crm-view-bar-height-desktop: 96px !default;
$crm-view-bar-height-mobile: 84px !default;

//

$crm-component-title-margin: 16px !default;
