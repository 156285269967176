@use '@angular/material' as mat;
// https://material.angular.io/guide/theming
// https://material.angular.io/guide/theming-your-components

// https://github.com/Teradata/covalent/blob/develop/src/theme.scss

@use '../app/core/directives/form-errors/form-errors.scss';
@use '../app/core/themes/core.scss';

// https://material.io/design/color/the-color-system.html
// https://github.com/angular/material2/blob/master/src/lib/core/theming/_palette.scss
@use './palette.scss' as palette;
@use './mat-variables.scss' as mat-variables;
@use './variables.scss' as variables;

// https://material.angular.io/guide/typography

/*

$custom-typography: mat.define-typography-config(
  $button: mat.define-typography-level(14px, 14px, 400)
);

$custom-toolbar-typography: mat.define-typography-config(
  $title: mat.define-typography-level(20px, 32px, 400)
);

*/

$custom-typography: mat.m2-define-typography-config($font-family: 'Open Sans, sans-serif'
  );

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`
@include mat.all-component-typographies($custom-typography);
@include mat.elevation-classes();
@include mat.app-background();
// @include mat.toolbar-typography($custom-toolbar-typography);

$primary: mat.m2-define-palette(palette.$mat-black, 900);
$accent: mat.m2-define-palette(mat.$m2-deep-orange-palette, 500, A100, A400);
$warn: mat.m2-define-palette(mat.$m2-red-palette, 600);

$theme: mat.m2-define-light-theme((color: (primary: $primary,
        accent: $accent,
        warn: $warn,
      ),
      density: -1 // https://stackoverflow.com/a/75194777/10752002
    ));

@include mat.all-component-themes($theme);

// https://material.angular.io/guide/theming-your-components

// top, right, bottom, and left

html,
body {
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  // font-family: Roboto, 'Helvetica Neue', sans-serif;
  // font-family: RobotoDraft, 'Helvetica Neue', sans-serif;
  font-family: 'Open Sans', sans-serif;
}

/*

.mat-drawer-content {
  overflow: hidden !important;
}

*/

#app-content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
}

.list-item-active {
  font-weight: bold;
  color: black !important;
  background: rgba(0, 0, 0, 0.04);
}

.mat-drawer-container {
  background-color: #BFDEDD;
}

// .crm-navigation-bar {
//   position: fixed;
//   top: 0;
//   left: 0;
//   right: 0;
//   z-index: 998;
// }

.core-command-bar {
  position: fixed;
  top: mat-variables.$mat-toolbar-height-desktop;
  left: 0;
  right: 0;
  z-index: 997;
  height: variables.$crm-command-bar-height-desktop;
  min-height: variables.$crm-command-bar-height-desktop;
  background-color: #f5f5f5;

  @media (mat-variables.$mat-xsmall) {
    top: mat-variables.$mat-toolbar-height-mobile;
    height: variables.$crm-command-bar-height-mobile;
    min-height: variables.$crm-command-bar-height-mobile;
  }
}

.core-command-bar-sidenav {
  margin-top: mat-variables.$mat-toolbar-height-desktop + variables.$crm-command-bar-height-desktop;

  @media (mat-variables.$mat-xsmall) {
    margin-top: mat-variables.$mat-toolbar-height-mobile + variables.$crm-command-bar-height-mobile;
  }
}

.crm-content-container {
  height: 100%;
}

.crm-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.crm-component-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: variables.$crm-view-bar-height-desktop;
  min-height: variables.$crm-view-bar-height-desktop;

  // margin-top: mat-variables.$mat-toolbar-height-desktop + variables.$crm-command-bar-height-desktop;

  @media (mat-variables.$mat-xsmall) {
    height: variables.$crm-view-bar-height-mobile;
    min-height: variables.$crm-view-bar-height-mobile;

    // margin-top: mat-variables.$mat-toolbar-height-mobile + variables.$crm-command-bar-height-mobile;
  }
}

.crm-component-title {
  // margin-top: $crm-component-title-margin;
  // margin-bottom: $crm-component-title-margin;
  // vertical-align: middle;
  // padding-top: 12px;
  margin: 0;
  // text-align: center;
}

.crm-dashboard-container {
  // mat-variables.mat-toolbar-height-desktop + variables.$crm-command-bar-height-desktop + variables.$crm-view-bar-height-desktop
  // 64 + 56 + 96
  height: calc(100vh - 216px);
  width: 100%;

  @media (mat-variables.$mat-xsmall) {
    // mat-variables.$mat-toolbar-height-mobile + variables.$crm-command-bar-height-mobile + variables.$crm-view-bar-height-mobile
    // 56 + 0 + 84
    height: calc(100vh - 140px);
  }
}

:fullscreen .crm-navigation-bar {
  display: none;
}

:fullscreen .crm-command-bar {
  display: none;
}

:fullscreen .crm-component-title-container {
  margin-top: 0;
}

:fullscreen .crm-dashboard-container {
  // variables.$crm-view-bar-height-desktop
  height: calc(100vh - 96px);
}

.crm-table-container {
  margin-left: 32px;
  margin-right: 32px;
  overflow: auto;

  // margin-top: mat-variables.$mat-toolbar-height-desktop + variables.$crm-command-bar-height-desktop;

  @media (mat-variables.$mat-xsmall) {
    margin-left: 0;
    margin-right: 0;

    // margin-top: mat-variables.$mat-toolbar-height-mobile + variables.$crm-command-bar-height-mobile;
  }
}

$task-list-width: 320px; // 240px;

.crm-activities-container {
  display: grid;
  grid-template-columns: $task-list-width auto;
  // grid-template-rows: 100%; deprecated
  // grid-template-rows: auto;
  // grid-template-rows: minmax(250px, auto);
  column-gap: 32px;
  // row-gap: 1em;

  margin-top: 0;
  margin-left: 32px;
  margin-bottom: 32px;
  margin-right: 32px;

  // overflow: auto;
  // overflow: hidden;
}

.crm-task-list-container {
  // background: white;
  border: 1px solid lightgray;
}

.crm-task-container {
  background: white;
  border: 1px solid lightgray;
  padding-top: 0;
  margin-top: 0;
  // background: whitesmoke;

  // padding-left: 32px;
  // padding-right: 32px;

  // overflow: auto;

  // height: 100%;
  // height: calc(100vh - (mat-variables.$mat-toolbar-height-desktop + variables.$crm-command-bar-height-desktop + variables.$crm-component-title-margin));
  // height: calc(100vh - 64 + 56 + 16);

  // height: 100vh;
  // height: calc(100vh - 64px + 56px + 16px);
  // height: calc(100vh - 96px);
  // height: calc(100vh - 128px);

  // height: 400px;
  height: calc(100vh - 256px);

  @media (mat-variables.$mat-xsmall) {
    height: calc(100vh - 200px);
  }

}

.crm-task-list-icon {
  // margin: 0 12px 0 12px;
  margin: 0;
  padding: 0;
}

.crm-grid-container {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  -ms-grid-columns: 1fr 1fr;

  column-gap: 32px;
  row-gap: 1em;

  margin-top: 0;
  margin-left: 32px;
  margin-bottom: 32px;
  margin-right: 32px;
}

.crm-snack-bar {
  // background: #00b862;
  text-align: center;
}

//
// Dynamic Reactive Forms
//

.crm-prefix-icon {
  cursor: pointer;
}

.crm-suffix-icon {
  cursor: pointer;
}

.crm-nested-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 16px;
  row-gap: 1em;
}

.grid-column-1 {
  grid-column: 1;
}

.grid-column-1-span-2 {
  grid-column: 1 / span 2;
}

.grid-column-2 {
  grid-column: 2;
}

.grid-column-2-span-2 {
  grid-column: 2 / span 2;
  // min-width: 180px;
}

.grid-column-3 {
  grid-column: 3;
}

.grid-column-4 {
  grid-column: 4;
}

.grid-row-2 {
  grid-row: 2;
}

//
// Library elements
//

/*

.lib-component-title {

  margin-top: mat-variables.$mat-toolbar-height-desktop + variables.$crm-component-title-margin;

  @media (mat-variables.$mat-xsmall) {
    margin-top: mat-variables.$mat-toolbar-height-mobile + variables.$crm-component-title-margin;
  }

  margin-bottom: variables.$crm-component-title-margin;
  text-align: center;
}

*/

.lib-component-title {

  margin-top: mat-variables.$mat-toolbar-height-desktop + variables.$crm-command-bar-height-desktop + variables.$crm-component-title-margin;
  margin-bottom: variables.$crm-component-title-margin;
  text-align: center;

  @media (mat-variables.$mat-xsmall) {
    margin-top: mat-variables.$mat-toolbar-height-mobile + variables.$crm-command-bar-height-mobile + variables.$crm-component-title-margin;
  }
}

.mat-mdc-header-row {
  // background-color: mat.get-color-from-palette($accent, 300);
  background-color: #f5f5f5;
}

.mat-mdc-footer-row {
  // background-color: mat.get-color-from-palette($accent, 300);
  background-color: #f5f5f5;
}

a:active {
  font-weight: bold;
  color: mat.m2-get-color-from-palette($accent, darker) !important;
  // color: blue;
}

.active {
  font-weight: bold;
  color: mat.m2-get-color-from-palette($accent, darker) !important;
  // color: blue;
}

.anchor {
  // font-weight: bold;
  color: #488aff;
  cursor: pointer;
}

.mat-mdc-row.hovered {
  background: #eee;
  cursor: pointer;
}

.flipped {
  transform: scaleX(-1);
}

.mat-mdc-tooltip-surface {
  white-space: pre-line !important;
  max-width: 300px !important;
  padding: 8px !important;
}

:root {
  @include mat.icon-button-overrides((icon-size: 35px));
  @include mat.tooltip-overrides((supporting-text-size: 1em));
}

.mat-mdc-icon-button.mat-mdc-button-base {
  padding: 0 !important;
}

.mat-badge-content {
  margin-left: 5px !important;
  top: -4px !important;
  position: relative !important;
  background: white !important;
  color: black !important;
  right: 0 !important;
}

.manual-mat-badge-small-override {
  // In order to get similar look and feel to a matbadge the element must also have the following classes:
  //  mat-badge-small mat-badge-content mat-badge-active

  width: var(--mat-badge-legacy-small-size-container-size, unset);
  height: var(--mat-badge-legacy-small-size-container-size, unset);
  min-width: var(--mat-badge-small-size-container-size, 6px);
  min-height: var(--mat-badge-small-size-container-size, 6px);
  line-height: var(--mat-badge-small-size-line-height, 6px);
  padding: var(--mat-badge-small-size-container-padding, 0);
  font-size: var(--mat-badge-small-size-text-size, 0);
  margin: var(--mat-badge-small-size-container-offset, -6px 0);
  top: -.65lh !important;
}

.mat-mdc-menu-panel {
  max-width: unset !important;
}

.no-scrolling-dialog {
  .mat-mdc-dialog-container {
    width: auto !important;
    height: auto !important;
  }
}

.mat-mdc-dialog-content {
  margin-top: 20px !important;
}

.mat-mdc-form-field-required-marker {
  font-weight: bold !important;
  color: red !important;
}

.mat-mdc-card-title {
  margin-bottom: 12px !important;
}

.icon-button-override {
  font-size: 24px !important;
  width: 24px !important;
  height: 24px !important;
}
