// https://github.com/angular/material2/blob/master/src/lib/core/style/_variables.scss
@use "../app/core/themes/header.scss";

$mat-xsmall: 'max-width: 599px';
$mat-small: 'max-width: 959px';

// https://github.com/angular/material2/blob/master/src/lib/toolbar/toolbar.scss

$mat-toolbar-height-desktop: 76px !default;
$mat-toolbar-height-mobile: 56px !default;
$mat-toolbar-row-padding: 16px !default;
